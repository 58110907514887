
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import store from '../../store'
import { keystoneV2 } from '../../store/modules/keystoneV2'
import NewFeatureRefsManagerTable from '../components/KeystoneV2/NewFeatureRefsManagerTable.vue'
import { usersMixin } from '../mixins/usersMixin'
import { baseViewMixin } from '../mixins/baseViewMixin'
import NewFeatureReferenceModel from '../models/Keystone_v2/NewFeatureReferenceModel'
import _ from 'lodash'
import NewFeatureRefsManagerForm from '../components/KeystoneV2/Form/NewFeatureReference/NewFeatureRefsManagerForm.vue'
import BasicSearchBar from '../components/Common/BasicSearchBar.vue'

// load the store module dynamically only when needed
if (!store.state.keystoneV2) store.registerModule('keystoneV2', keystoneV2)

@Component({
  components: {
    NewFeatureRefsManagerTable,
    NewFeatureRefsManagerForm,
    BasicSearchBar
  }
})
export default class NewFeatureRefsManager extends mixins(usersMixin, baseViewMixin) {
  // FILTERS
  search: string = null

  // FEATURES
  newFeatureReferences: Array<NewFeatureReferenceModel> = null
  shownNewFeatureReferences: Array<NewFeatureReferenceModel> = null

  loading: boolean = true

  // FORM
  newFeatureDialogOn: boolean = false
  newFeatureRefToEdit: NewFeatureReferenceModel = null

  async mounted () {
    this.onMounted()
    await this.getNewFeatureReferences()
    this.retrieveDataFromQueryParams()
    this.loading = false
  }

  reload () {
    this.getNewFeatureReferences()
  }

  // API CALLS

  async getNewFeatureReferences () {
    this.loading = true
    this.newFeatureReferences = []

    const result = await this.$apiCaller.getNewFeatureReferences()
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling getNewFeatureReferences')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      result.data.forEach((data: any) => {
        this.newFeatureReferences.push(new NewFeatureReferenceModel(data))
        this.shownNewFeatureReferences = this.newFeatureReferences
      })
    }
    this.loading = false
  }

  async createNewFeatureReference (newFeatureRef: NewFeatureReferenceModel) {
    const result = await this.$apiCaller.createNewFeatureReferences(newFeatureRef.getAsApiArgs())
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling createNewFeatureReferences')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      this.snackBarConfig = { type: 'success', color: 'sucess', message: 'New Feature Reference successfully created!' }
      this.snackbar = true
    }
    this.closeNewFeatureReferenceDialog(true)
  }

  async updateNewFeatureReference (editedFeature: NewFeatureReferenceModel) {
    console.log('test', editedFeature.getAsApiArgs())
    const result = await this.$apiCaller.updateNewFeatureReference(editedFeature.getAsApiArgs())
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling updateNewFeatureReference')
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      this.snackBarConfig = { type: 'success', color: 'sucess', message: 'New Feature Reference successfully updated!' }
      this.snackbar = true
    }
    this.closeNewFeatureReferenceDialog(true)
  }

  // QUERY PARAMS
  refreshNewFeaturesReferenceWithNewQueryParams (search: string) {
    this.search = search
    this.shownNewFeatureReferences = this.newFeatureReferences.filter(
      (ft: NewFeatureReferenceModel) => {
        return (
          ft.featureName.toLowerCase().includes(this.search.toLowerCase()) ||
          ft.devName.toLowerCase().includes(this.search.toLowerCase())
        )
      }
    )
    let queryParams: { [key: string]: any } = {}
    if (this.search != null && this.search !== '') {
      queryParams.search = this.search
    }
    if (!_.isEqual(this.$route.query, queryParams)) {
      this.$router.replace({
        name: 'newFeatureRefsManager',
        params: { userId: this.$store.getters.getCurrentUserId },
        query: queryParams
      })
    }
  }

  retrieveDataFromQueryParams () {
    this.search = Object.keys(this.$route.query).indexOf('search') !== -1 ? (this.$route.query.search as string) : null
    if (Object.keys(this.$route.query).indexOf('edit') !== -1) {
      const devNameToEdit: string = this.$route.query.edit as string
      const toEditArray: Array<NewFeatureReferenceModel> = this.newFeatureReferences.filter(x => x.devName === devNameToEdit)
      const toEdit: NewFeatureReferenceModel = toEditArray.length === 1 ? toEditArray[0] : null
      if (toEdit == null) { return }
      this.openEditNewFeatureReferenceDialog(toEdit)
    }
  }

  // DIALOG
  openNewFeatureReferenceDialog () {
    this.newFeatureDialogOn = true
    this.newFeatureRefToEdit = null
  }
  openEditNewFeatureReferenceDialog (toEdit: NewFeatureReferenceModel) {
    this.newFeatureDialogOn = true
    this.newFeatureRefToEdit = toEdit
  }
  closeNewFeatureReferenceDialog (refresh: boolean = false) {
    this.newFeatureDialogOn = false
    if (refresh) {
      this.reload()
    }
  }

  // SNACKBAR
  set snackbar (newValue: any) {
    this.$store.commit('setActiveSnackBar', newValue)
  }
  get snackbar () {
    return this.$store.getters.getActiveSnackBar
  }
  set snackBarConfig (config: any) {
    this.$store.commit('setSnackBarConfig', config)
  }
  get snackBarConfig () {
    return this.$store.getters.getSnackBarConfig
  }
}
